import React from 'react';
import Card from 'react-bootstrap/Card';
import FullTimeIcon from '../../assets/uim_bag.png';
import LocationIcon from '../../assets/uim_loc.png';
import './CardCareer.css';

function CardCareer({ position, type, location, location_preference, career_id }) {
    return (
        <Card className="custom-card" onClick={() => { window.location.href = `/career/${career_id}` }}>
            <Card.Body>
                <p className='card-career-title'>{position}</p>
                    <div className="card-info-time">
                        <img src={FullTimeIcon} alt="Full Time Icon" />
                        <span>{type}</span>
                    </div>
                    <div className="card-info-location">
                        <img src={LocationIcon} alt="Location Icon" />
                        <span>{location}</span>
                    </div>
                    <div className="onsite">{location_preference}</div>
            </Card.Body>
        </Card>
    );
}

export default CardCareer;
