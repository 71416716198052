import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logoNetzap from '../../assets/logo-netzap.png';
import './ReferensiEvent.css';

function ReferensiEvent() {
    const [activeYear, setActiveYear] = useState(2024);

    const handleYearChange = (year) => {
        setActiveYear(year);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        rows: 2,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    rows: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 1,
                }
            }
        ]
    };
    
    return (
        <div className="referensi-container">
            <h1 className='title-list-of-referensi'>Referensi Event</h1>
            <div className="slider">
                <button className={activeYear === 2024 ? 'active' : ''} onClick={() => handleYearChange(2024)}>2024</button>
                <button className={activeYear === 2023 ? 'active' : ''} onClick={() => handleYearChange(2023)}>2023</button>
                <button className={activeYear === 2022 ? 'active' : ''} onClick={() => handleYearChange(2022)}>2022</button>
                <button className={activeYear === 2021 ? 'active' : ''} onClick={() => handleYearChange(2021)}>2021</button>
                <button className={activeYear === 2020 ? 'active' : ''} onClick={() => handleYearChange(2020)}>2020</button>
            </div>

            <div className="content">
                {activeYear === 2024 && (
                    <Slider {...settings}>
                        {[...Array(12)].map((_, index) => (
                            <div key={index}>
                                <img src={logoNetzap} alt={`Slide ${index + 1}`} style={{ width: 300 }} />
                            </div>
                        ))}
                    </Slider>
                )}
                {activeYear === 2023 && (
                    <Slider {...settings}>
                        {[...Array(12)].map((_, index) => (
                            <div key={index}>
                                <img src={logoNetzap} alt={`Slide ${index + 1}`} style={{ width: 300 }} />
                            </div>
                        ))}
                    </Slider>
                )}
                {activeYear === 2022 && (
                    <Slider {...settings}>
                        {[...Array(12)].map((_, index) => (
                            <div key={index}>
                                <img src={logoNetzap} alt={`Slide ${index + 1}`} style={{ width: 300 }} />
                            </div>
                        ))}
                    </Slider>
                )}
                {activeYear === 2021 && (
                    <Slider {...settings}>
                        {[...Array(12)].map((_, index) => (
                            <div key={index}>
                                <img src={logoNetzap} alt={`Slide ${index + 1}`} style={{ width: 300 }} />
                            </div>
                        ))}
                    </Slider>
                )}
                {activeYear === 2020 && (
                    <Slider {...settings}>
                        {[...Array(12)].map((_, index) => (
                            <div key={index}>
                                <img src={logoNetzap} alt={`Slide ${index + 1}`} style={{ width: 300 }} />
                            </div>
                        ))}
                    </Slider>
                )}
            </div>
        </div>
    );
}

export default ReferensiEvent;
