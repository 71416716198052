import React from 'react';
import Navbar from '../components/Navbar/Navbar.js';
import Footer from '../components/Footer/Footer.js';
import FirstSection from '../components/ServiceComponents/firstSection.js';
import SecondSection from '../components/ServiceComponents/secondSection.js';
import ThirdSection from '../components/ServiceComponents/thirdSection.js';
import FourthSection from '../components/ServiceComponents/fourthSection.js';
import AboutSection from '../components/ServiceComponents/aboutSection.js'

export default function ServicePage() {
    return (
        <div>
            <Navbar></Navbar>
            <FirstSection></FirstSection>
            <SecondSection></SecondSection>
            <ThirdSection></ThirdSection>
            <FourthSection> </FourthSection>
            <AboutSection></AboutSection>
            <Footer></Footer>
        </div>
    )
}