import React from 'react';
import Navbar from '../../components/Navbar/Navbar.js';
import Footer from '../../components/Footer/Footer.js';
import TentangKami from '../../components/TentangKami/TentangKami.js';
import visiMisi from '../../assets/visi-misi.png';
import NilaiPerusahaan from '../../components/NilaiPerusahaan/NilaiPerusahaan.js';
import ReferensiEvent from '../../components/ReferensiEvent/ReferensiEvent.js';
import PelangganReguler from '../../components/PelangganReguler/PelangganReguler.js';
import './AboutUsPage.css';

export default function AboutUsPage() {
    return (
        <div>
            <Navbar></Navbar>
            <TentangKami></TentangKami>
            <img src={visiMisi} alt="Visi Misi Netzap" className="visi-misi" />
            <NilaiPerusahaan></NilaiPerusahaan>
            <ReferensiEvent></ReferensiEvent>
            <PelangganReguler></PelangganReguler>
            <Footer></Footer>
        </div>
    )
}