import React from 'react'
import Navbar from '../components/Navbar/Navbar.js';
import Footer from '../components/Footer/Footer.js';
import ListOfArticles from '../components/ListOfArticles/ListOfArticles';


function ArticlesPage() {
  return (
    <div>
      <Navbar></Navbar>
      <ListOfArticles></ListOfArticles>
      <Footer></Footer>
    </div>
  )
}

export default ArticlesPage