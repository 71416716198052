import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css';
import LandingPage from './pages/landingPage';
import ServicePage from './pages/ServicePage';
import ArticlesPage from './pages/ArticlesPage';
import ArticleDetailsPage from './pages/ArticleDetailsPage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import CareerDetail from './pages//CareerDetailPage';

function App() {
  return (
    <Router basename="/netzap">
      <Switch>
        <Route exact path="/"><LandingPage /></Route>
        <Route path="/service-page"><ServicePage /></Route>
        <Route exact path="/article"><ArticlesPage /></Route>
        <Route path="/article/:id" component={ArticleDetailsPage} />
        <Route path="/about-us"><AboutUsPage /></Route>
        <Route path="/career/:career_id" component={CareerDetail}></Route>
      </Switch>
    </Router>
  );
}

export default App;
