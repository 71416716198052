import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './PelangganReguler.css';
import logoNetzap from '../../assets/logo-netzap.png';
import NextArrow from '../../assets/arrow.png';

function PelangganReguler() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className="pelanggan-container">
            <h1 className='title-list-of-pelanggan'>Pelanggan Reguler</h1>
            <div className="image-slider">
                <Slider {...settings}>
                    {[...Array(10)].map((_, index) => (
                        <div key={index}>
                            <img src={logoNetzap} alt={`Slide ${index + 1}`} style={{ width: 300 }} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <img src={NextArrow} className={className} style={{ ...style}} onClick={onClick} alt="Next" />
    );
}

export default PelangganReguler;
