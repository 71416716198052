import React from 'react';
import './Footer.css'; 
import Container from 'react-bootstrap/Container';
import logoNetzap from '../../assets/logo-netzap.png';

const Footer = () => {
    return (
        <div className="Footer">
            <Container>
            <div className="row">
                    <div className="col-sm ft-1">
                    <img src={logoNetzap} alt="Logo Netzap" className="footer-logo" />
                        <p>PT. Solusi Aksesindo pratama</p>
                        <p>Jl. Gunawarman No.67 Kebayoran Baru, Jakarta 12180</p>
                        <br></br>
                        <p>Customer Service</p>
                        <p>+62 21 7396364</p>
                        <p>cs@net-zap.com</p>
                        <br></br>
                        <p>Sales</p>
                        <p>sales@net-zap.com</p>
                    </div>
                    <div className="col-sm ft-2">
                        <h5>Ikuti Kami</h5>
                        <div className="footer-icons">
                            <a href="https://www.instagram.com/netzap_internet/">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a href="#">
                                <i className="fa-brands fa-facebook"></i>
                            </a>
                            <a href="#">
                                <i className="fa-brands fa-tiktok"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/solusiaksesindopratama/">
                                <i className="fa-brands fa-linkedin-in"></i>
                            </a>
                            <a href="#">
                                <i className="fa-brands fa-youtube"></i>
                            </a>
                        </div>

                    </div>
                </div>
            </Container>
        </div>
    )
}
export default Footer