import React, { useState, useEffect } from 'react';
import CardCareer from './CardCareer';
import './ListOfCareers.css';

function ListOfCareers() {
    const [careers, setCareers] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [allCareersDisplayed, setAllCareersDisplayed] = useState(false);

    useEffect(() => {
        fetch('https://api.net-zap.com/api.php?action=get_list_of_careers')
            .then(response => response.json())
            .then(data => {
                setCareers(data);
            })
            .catch(error => console.error('Error fetching careers:', error));
    }, []);

    const handleShowAll = () => {
        setExpanded(true);
        setAllCareersDisplayed(true);
    };

    return (
        <div className="career-container">
            <h1 className='title-list-of-careers'>Karir</h1>
            <div className="card-container">
                {expanded ? careers.map((career, index) => (
                    <CardCareer
                        key={index}
                        position={career.position}
                        type={career.type}
                        location={career.location}
                        location_preference={career.location_preference}
                        career_id={career.career_id}
                    />
                )) : careers.slice(0, 3).map((career, index) => (
                    <CardCareer
                        key={index}
                        position={career.position}
                        type={career.type}
                        location={career.location}
                        location_preference={career.location_preference}
                        career_id={career.career_id}
                    />
                ))}
            </div>
            <div className="button-container">
                <button type='button' onClick={handleShowAll} disabled={allCareersDisplayed}>
                    {allCareersDisplayed ? "Semua Karir Telah Ditampilkan" : "Lihat Lainnya"}
                </button>
            </div>
        </div>
    );
}

export default ListOfCareers;
