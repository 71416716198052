import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import FullTimeIcon from '../../assets/uim_bag.png';
import LocationIcon from '../../assets/uim_loc.png';
import CardCareer from '../ListOfCareers/CardCareer';
import './CareerDetail.css';

function CareerDetail() {
    const [career, setCareer] = useState(null);
    const [otherCareers, setOtherCareers] = useState([]);
    const { career_id } = useParams();

    useEffect(() => {
        fetch(`https://api.net-zap.com/api.php?action=get_other_careers&career_id=${career_id}`)
            .then(response => response.json())
            .then(data => setOtherCareers(data))
            .catch(error => console.error('Error fetching other articles:', error));

        fetch(`https://api.net-zap.com/api.php?action=get_career_details&career_id=${career_id}`)
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data) && data.length > 0) {
                    setCareer(data);
                } else {
                    console.error('Invalid career data:', data);
                }
            })
            .catch(error => console.error('Error fetching career:', error));
    }, [career_id]);

    if (!career) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container-career-detail">
            <div className="career-detail-info">
                <p className='career-info-title'>{career[0].position}</p>
                <div className="career-info-time">
                    <img src={FullTimeIcon} alt="Full Time Icon" />
                    <span>{career[0].type}</span>
                </div>
                <div className="career-info-location">
                    <img src={LocationIcon} alt="Location Icon" />
                    <span>{career[0].location}</span>
                </div>
                <p>{career[0].description}</p>
                <p>{career[0].heading_sec}</p>
                <p>{career[0].text_sec}</p>
                <p>{career[0].heading_subsec}</p>
                <p>{career[0].text_subsec}</p>
            </div>

            <div className="career-detail-card">
                <h2 className="position-title">Posisi Lainnya</h2>
                {otherCareers.map((career, index) => (
                    <CardCareer
                        key={index}
                        position={career.position}
                        type={career.type}
                        location={career.location}
                        location_preference={career.location_preference}
                        career_id={career.career_id}
                    />
                ))}
            </div>
        </div>
    );
}

export default CareerDetail;
