import React, { useState } from "react";
import './FormModal.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


const FormModal =({open, onClose}) =>{
    const [selectedTab, setSelectedTab] = useState("a");
    const [name, setName] = useState('')
    const [noPhone, setNoPhone] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [event, setEvent] = useState('')
    const [time, setTime] = useState('')
    const [location, setLocation] = useState('')
    const [need , setNeed] = useState('') 

    if(!open){
        return null;
    }

    const handleSubmitTabA = (e) => {
        e.preventDefault();
        if (name === '' || noPhone === '' || email === '' || address === '') {
            toast.error('Mohon lengkapi semua field yang diperlukan.');
            return;
        }
        const data = {
            "Nama" : name,
            "No Telepon": noPhone,
            "Email": email,
            "Alamat": address
        }
        axios.post("https://sheet.best/api/sheets/f3eadb91-801b-43ed-a3dd-d35d304fbe3a",data).then((response=>{ //insert API here
            toast.success('Data berhasil disimpan!', {
                position: "top-right" 
            });
            setName('')
            setNoPhone('')
            setEmail('')
            setAddress('')
        }))



    };
    
    const handleSubmitTabB = (e) => {
        e.preventDefault();
        if (name === '' || noPhone === '' || email === '' || event === '' || time === '' || location === '') {
            toast.error('Mohon lengkapi semua field yang diperlukan.');
            return;
        }
        const data = {
            "Nama" : name,
            "No Telepon": noPhone,
            "Email": email,
            "Lokasi": location,
            "Nama Event" : event,
            "Waktu" : time
        }
        axios.post("https://sheet.best/api/sheets/2ac49f28-3b58-43d4-b34f-d69f1afbf8a9",data).then((response=>{ //insert API here
            console.log(data)
            toast.success('Data berhasil disimpan!', {
                position: "top-right"
            });
            setName('')
            setNoPhone('')
            setEmail('')
            setLocation('')
            setEvent('')
            setTime('')
        }))
        

    };
    
    const handleSubmitTabC = (e) => {
        e.preventDefault();
        if (name === '' || noPhone === '' || email === '' || address === '' || need === '') {
            toast.error('Mohon lengkapi semua field yang diperlukan.');
            return;
        }
        const data = {
            "Nama" : name,
            "No Telepon": noPhone,
            "Email": email,
            "Alamat": address,
            "Kebutuhan" : need,
        }
        axios.post("https://sheet.best/api/sheets/d35d752c-55ad-45ad-bddf-9f50d6ac6e99",data).then((response=>{ //insert API here
            
            toast.success('Data berhasil disimpan!', {
                position: "top-right" 
            });
            setName('')
            setNoPhone('')
            setEmail('')
            setAddress('')
            setNeed('')
        }))
        

    };
    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
    let rightContent;
    if (selectedTab === "a") {
        rightContent =  <div>
        <form onSubmit={handleSubmitTabA}>
        <div className="form-group">
            <label >Nama:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Nama Lengkap'
            type="text"
            onChange={(e)=>setName(e.target.value)} value={name} />
        </div>
        <div className="form-group">
            <label>No Telp:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Nomor Telepon'
            type="text"
            onChange={(e)=>setNoPhone(e.target.value)} value={noPhone} />
        </div>
        <div className="form-group">
            <label>Email:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Email'
            type="text" 
            onChange={(e)=>setEmail(e.target.value)} value={email}/>
        </div>
        <div className="form-group">
            <label>Alamat:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Alamat'
            type="text" 
            onChange={(e)=>setAddress(e.target.value)} value={address}/>
        </div>
        <button className="button-submit-modal" type="submit">Submit</button>
    </form>
    </div>
    } else if (selectedTab === "b") {
        rightContent = <form onSubmit={handleSubmitTabB}>
        <div className="form-group">
            <label >Nama:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Nama Lengkap'
            type="text" 
            onChange={(e)=>setName(e.target.value)} value={name}/>
        </div>
        <div className="form-group">
            <label>No Telp:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Nomor Telepon'
            type="text" 
            onChange={(e)=>setNoPhone(e.target.value)} value={noPhone}/>
        </div>
        <div className="form-group">
            <label>Email:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Email'
            type="text" 
            onChange={(e)=>setEmail(e.target.value)} value={email}/>
        </div>
        <div className="form-group">
            <label>Nama Acara:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Nama Acara'
            type="text"
            onChange={(e)=>setEvent(e.target.value)} value={event} />
        </div>
        <div className="form-group">
            <label>Waktu:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Waktu'
            type="text" 
            onChange={(e)=>setTime(e.target.value)} value={time}/>
        </div>
        <div className="form-group">
            <label>Lokasi:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Lokasi'
            type="text" 
            onChange={(e)=>setLocation(e.target.value)} value={location}/>
        </div>
        <button className="button-submit-modal" type="submit">Submit</button>
    </form>
    } else if (selectedTab === "c") {
        rightContent =  <form onSubmit={handleSubmitTabC}>
        <div className="form-group">
            <label >Nama:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Nama Lengkap'
            type="text"
            onChange={(e)=>setName(e.target.value)} value={name} />
        </div>
        <div className="form-group">
            <label>No Telp:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Nomor Telepon'
            type="text"
            onChange={(e)=>setNoPhone(e.target.value)} value={noPhone} />
        </div>
        <div className="form-group">
            <label>Email:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Email'
            type="text" 
            onChange={(e)=>setEmail(e.target.value)} value={email}/>
        </div>
        <div className="form-group">
            <label>Alamat:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Alamat'
            type="text" 
            onChange={(e)=>setAddress(e.target.value)} value={address}/>
        </div>
        <div className="form-group">
            <label>Kebutuhan:</label>
            <span style={{ color: "red" }}>*</span>
            <br/>
            <input 
            placeholder='Kebutuhan'
            type="text"
            onChange={(e)=>setNeed(e.target.value)} value={need} />
        </div>
        <button className="button-submit-modal" type="submit">Submit</button>
    </form>
    }

    return (
        
    <div className="modal-overlay">
        <ToastContainer />
        <div className="modal-box">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <div className="container-modal">
                    <div className="left">
                        <button className='modal-button' onClick={() => handleTabClick("a")}>Daftar Wilayah untuk Pemasangan Internet</button>
                        <br/>
                        <button className='modal-button' onClick={() => handleTabClick("b")}>Internet untuk Event</button>
                        <br/>
                        <button className='modal-button' onClick={() => handleTabClick("c")}>Internet untuk UMKM atau Backup Wifi atau Lainnya</button>
                    </div>
                    <div className="right">
                        {rightContent}
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    );
};

export default FormModal;


