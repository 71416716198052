import React from 'react'
import Navbar from '../components/Navbar/Navbar.js';
import Footer from '../components/Footer/Footer.js';
import CareerDetail from '../components/CareerDetail/CareerDetail.js';

function CareerDetailPage() {
  return (
    <div>
      <Navbar></Navbar>
      <CareerDetail></CareerDetail>
      <Footer></Footer>
    </div>
  )
}

export default CareerDetailPage;