import React from 'react';
import './TentangKami.css';

function TentangKami() {
    return (
        <div className="tentang-kami-container">
            <h1 className='title-tentang-kami'>Tentang Kami</h1>
            <h6>netZAP merupakan brand dari PT. Solusi Aksesindo Pratama yang didirikan pada tahun 2003 dan beroperasi pada tahun 2005. PT. Solusi Aksesindo Pratama telah memiliki izin ISP dari Kementrian KOMINFO.</h6>
        </div>
    );
}

export default TentangKami;
