import React from "react";
import "./Feedback.css";

function Feedback() {
    return (
        <div className="feedback-container">
            <div className="column">
                <div className="video-container">
                    <iframe
                        width="100%"
                        height="350"
                        src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </div>
            </div>

            <div className="column">
                <h2 className="title-testimony">Apa Kata Mereka?</h2>
                <div className="testimonial-container">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero in neque efficitur, sed fringilla enim dapibus. Nullam dictum nisl at ipsum vestibulum, vitae volutpat eros pellentesque.
                        </p>
                        <p>
                            by John Doe on <span className="date">Feb 01, 2024</span>
                        </p>
                </div>   
            </div>
        </div>
    );
}

export default Feedback;
