import React from 'react';
import Card from 'react-bootstrap/Card';
import './CardNilaiPerusahaan.css';

function CardNilaiPerusahaan({ number, title, description }) {
    return (
        <Card className="custom-card card-nilai">
            <Card.Body>
                <h3 className='card-info-number'>{number}</h3>
                <h3 className="card-info-title">{title}</h3>
                <div className="card-info-description">
                    <span>{description}</span>
                </div>
            </Card.Body>
        </Card>
    );
}

export default CardNilaiPerusahaan;
