import React, { useEffect, useState } from 'react';
import './Articles.css'
import CardArticle from './CardArticle'

function ListOfArticles() {
    const [articles, setArticles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 9;

    useEffect(() => {
        fetch(`https://api.net-zap.com/api.php?action=get_articles`)
            .then(response => response.json())
            .then(data => setArticles(data))
            .catch(error => console.error('Error fetching articles:', error));
    }, []);

    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);
    const totalPages = Math.ceil(articles.length / articlesPerPage);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginationButtons = [];
    const maxButtonsToShow = 3;
    const startPage = Math.max(1, currentPage - Math.floor(maxButtonsToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxButtonsToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
        paginationButtons.push(
            <button
                key={i}
                onClick={() => paginate(i)}
                className={`pagination-button ${currentPage === i ? 'active' : ''}`}
            >
                {i}
            </button>
        );
    }

    return (
        <>
            <div className="article-section">
                <h1 className='title-list-of-articles'>Artikel</h1>
                <div className='article-page-container'>
                    {currentArticles.map((article, index) => (
                        <CardArticle
                            key={index}
                            image={article.image_data}
                            id={article.article_id}
                            title={article.title}
                            description={article.description}
                        />
                    ))}
                </div>
                <div className="pagination">
                    {currentPage > 2 && (
                        <div>
                            <button onClick={() => paginate(1)} className='pagination-button'>1</button>
                        </div>
                    )}
                    {currentPage > 2 && (
                        <div className='pagination-blank'>..</div>
                    )}
                    {paginationButtons}
                    {currentPage <= totalPages - 2 && (
                        <div className='pagination-blank'>..</div>
                    )}
                    {currentPage <= totalPages - 2 && (
                        <button onClick={() => paginate(totalPages)} className='pagination-button'>{totalPages}</button>
                    )}
                </div>
            </div>
        </>
    )
}

export default ListOfArticles