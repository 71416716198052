import React from 'react';
import './Articles.css'
import { Link } from 'react-router-dom';

function CardArticle({ image, title, description, id }) {
    return (
        <div onClick={() => { window.location.href = `/article/${id}` }} className='article-card' style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className='wrapper'>
                <img
                    src={`data:image/jpeg;base64,${image}`}
                    alt="Article's image"
                />
            </div>
            <div className='article-detail'>
                <div className='article-card-title'>{title}</div>
                <div className='article-desc'>{description}</div>
            </div>
        </div>
    );
}

export default CardArticle
