import React from "react";
import logoNetzap from '../../assets/logo-netzap.png';
import "./Header.css";
import FormModal from '../FormModal/formModal.js'

function Header() {
    const [showModal, setShowModal] = React.useState(false);
    const handleOpenModal = () => {
        setShowModal(true);
      };
    
      const handleCloseModal = () => {
        setShowModal(false);
      };

    return (
        <div className="header">
            <img src={logoNetzap} alt="NetZap Logo" className="logo" />
            <div className="text">Bangun Jaringan Internet Tanpa Batas</div>
            <div className="button-container">
                <button type='submit' onClick={handleOpenModal} >Daftarkan Wilayahmu!</button>
            </div>
            <FormModal open={showModal} onClose={handleCloseModal}></FormModal>
        </div>
    );
}

export default Header;
