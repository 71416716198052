import React from 'react';
import Navbar from '../components/Navbar/Navbar.js';
import Footer from '../components/Footer/Footer.js';
import ListOfCareer from '../components/ListOfCareers/ListOfCareers.js';
import HighlightedArticles from '../components/ListOfArticles/HighlightedArticles.js';
import Services from '../components/Services/Services.js';
import Header from '../components/Header/Header.js';
import Hero from '../components/Hero/Hero.js';
import EventClient from '../components/EventClient/EventClient.js';
import EventReguler from '../components/EventReguler/EventReguler.js';
import Feedback from '../components/Feedback/Feedback.js';

export default function LandingPage() {
    return (
        <div>
            <Navbar></Navbar>
            <Header></Header>
            <Hero></Hero>
            <Services></Services>
            <EventClient></EventClient>
            <EventReguler></EventReguler>
            <Feedback></Feedback>
            <HighlightedArticles></HighlightedArticles>
            <ListOfCareer></ListOfCareer>
            <Footer></Footer>
        </div>
    )
}