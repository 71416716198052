import React from 'react';
import CardNilaiPerusahaan from './CardNilaiPerusahaan';
import './NilaiPerusahaan.css';

function NilaiPerusahaan() {
    return (
        <div className="nilai-perusahaan-container">
            <h1 className='title-nilai-perusahaan'>Nilai-nilai Perusahaan</h1>
            <div className="card-container">
                <CardNilaiPerusahaan className="card-nilai-perusahaan" number="01" title="Winning Spirit" description="Berupaya mengubah mimpi menjadi kenyataan dengan antusiasme yang tinggi untuk meraih inovasi yang menginspirasi." />
                <CardNilaiPerusahaan number="02" title="Professional" description="Berkomitmen pada standar etika tinggi, memberikan layanan yang terpercaya dan profesional." />
                <CardNilaiPerusahaan number="03" title="Customer Focus" description="Mengutamakan kepuasan pelanggan dengan layanan personal dan perhatian yang mendalam." />
                <CardNilaiPerusahaan number="04" title="Open Mind" description="Terbuka terhadap inovasi dan keberagaman, serta menciptakan lingkungan sekitar yang terus berkembang." />
                <CardNilaiPerusahaan number="05" title="Bring Joy to Others" description="Menyebarkan kebahagiaan melalui interaksi, menciptakan budaya yang membawa produktivitas yang positif dalam bekerja." />
            </div>
        </div>
    );
}

export default NilaiPerusahaan;
