import React, { useState } from "react";
import './AboutSection.css';
import FormModal from '../FormModal/formModal.js'

export default function AboutSection () {
    const [showModal, setShowModal] = React.useState(false);
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
    setShowModal(false);
    };
    const handleOpenWhatsApp = () => {
        window.open("https://wa.me/08217396364", "_blank");
    };
      
    return (
        
        <section id="aboutSection" style={{paddingTop:200, paddingBottom:100}}>

            <div className="box">
                <div className="h2-cont">
                    <h2>Tuliskan kebutuhanmu atau Hubungi Kami</h2>
                </div>
                <div className="container-about">
                    <div className="left-column-about">
                        <p>
                            Atau tuliskan kebutuhanmu melalui pengisian form
                            <br/>
                            untuk kami hubungi!
                        </p>
                        <div className="button-container-about">
                            <button className="button-daftar-about" onClick={handleOpenModal}>Daftar</button>
                            <FormModal open={showModal} onClose={handleCloseModal}></FormModal>
                        </div>
                    </div>
                    <div className="right-column-about">
                        <p>Hubungi kami
                            <br/>
                            untuk berkonsultasi secara langsung
                        </p>
                        <div className="button-container-about">
                            <button className="button-sales-about" onClick={handleOpenWhatsApp}>Hubungi Kami</button>
                        </div>
                    </div>
                </div>
            </div>

       
        </section>
    );
}
