import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from 'uuid';
import { config } from "react-spring";
import Service1 from '../../assets/Service1.png'
import Service2 from '../../assets/Service2.png'
import Service3 from '../../assets/Service3.png'
import Service4 from '../../assets/Service4.png'
import Service5 from '../../assets/Service5.png'

const getTouches = (evt) => {
    return (
        evt.touches || evt.originalEvent.touches
    );
};

export default class ServiceCarousel extends Component {
    state = {
        goToSlide: 0,
        offsetRadius: 2,
        showNavigation: false,
        enableSwipe: true,
        config: config.slow
    };

    slides = [
        {
            key: uuidv4(),
            content: (
                <div className='services-card'>
                    <img
                        src={Service1}
                        alt="Description of the image"
                        style={{ width: '100%', height: '100%', aspectRatio: '1/1', objectFit: 'cover' }}
                    />
                </div>
            )
        },
        {
            key: uuidv4(),
            content: (
                <div className='services-card'>
                    <img
                        src={Service2}
                        alt="Description of the image"
                        style={{ width: '100%', height: '100%', aspectRatio: '1/1', objectFit: 'cover' }}
                    />
                </div>
            )
        },
        {
            key: uuidv4(),
            content: (
                <div className='services-card'>
                    <img
                        src={Service3}
                        alt="Description of the image"
                        style={{ width: '100%', height: '100%', aspectRatio: '1/1', objectFit: 'cover' }}
                    />
                </div>
            )
        },
        {
            key: uuidv4(),
            content: (
                <div className='services-card'>
                    <img
                        src={Service4}
                        alt="Description of the image"
                        style={{ width: '100%', height: '100%', aspectRatio: '1/1', objectFit: 'cover' }}
                    />
                </div>
            )
        },
        {
            key: uuidv4(),
            content: (
                <div className='services-card'>
                    <img
                        src={Service5}
                        alt="Description of the image"
                        style={{ width: '100%', height: '100%', aspectRatio: '1/1', objectFit: 'cover' }}
                    />
                </div>
            )
        }
    ].map((slide, index) => {
        return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
    });

    onChangeInput = (e) => {
        this.setState({
            [e.target.name]: parseInt(e.target.value, 10) || 0
        });
    };

    handleTouchStart = (evt) => {
        if (!this.state.enableSwipe) {
            return;
        }

        const firstTouch = getTouches(evt)[0];
        this.setState({
            ...this.state,
            xDown: firstTouch.clientX,
            yDown: firstTouch.clientY
        });
    };

    handleTouchMove = (evt) => {
        if (!this.state.enableSwipe || (!this.state.xDown && !this.state.yDown)) {
            return;
        }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = this.state.xDown - xUp;
        let yDiff = this.state.yDown - yUp;
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                this.setState({
                    goToSlide: this.state.goToSlide + 1,
                    xDown: null,
                    yDown: null
                });
            } else {
                this.setState({
                    goToSlide: this.state.goToSlide - 1,
                    xDown: null,
                    yDown: null
                });
            }
        }
    };

    render() {
        return (
            <div
                style={{ width: "80%", height: "300px", margin: "0 auto" }}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
            >
                <Carousel
                    slides={this.slides}
                    goToSlide={this.state.goToSlide}
                    offsetRadius={this.state.offsetRadius}
                    showNavigation={this.state.showNavigation}
                    animationConfig={this.state.config}
                />
            </div>
        );
    }
}
