import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logoNetzap from '../../assets/logo-netzap.png';
import Button from "../Button/Button.js";
import './Navbar.css';
import { NavbarCollapse, NavbarToggle } from "react-bootstrap";

function NavbarNetZap() {
  const handleOpenWhatsApp = () => {
    window.open("https://wa.me/08217396364", "_blank");
  };
  return (
    <div>
      <Navbar className="navbar-custom" expand="lg">
        <Container>
          <Navbar.Brand href="#home" className="brand">
            <img src={logoNetzap} alt="Logo Netzap" />
          </Navbar.Brand>
          <NavbarToggle aria-controls="basic-navbar-nav"></NavbarToggle>
          <NavbarCollapse id="basic-navbar-nav">
            <Nav className="nav-item ms-auto">
              <Nav.Link href="/" >Beranda</Nav.Link>
              <Nav.Link href="/about-us" >Tentang</Nav.Link>
              <Nav.Link href="/service-page" >Layanan</Nav.Link>
              <Nav.Link href="/article" >Artikel</Nav.Link>
              <Nav.Link href="#career" >Karir</Nav.Link>
            </Nav>
            <Nav className="ms-auto">
              <Button className="button">
                <div className="button-contact" onClick={handleOpenWhatsApp} >Contact Us</div>
              </Button>
            </Nav>
          </NavbarCollapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarNetZap;