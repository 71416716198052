import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Articles.css'

function ArticleDetails() {
    const [articleData, setArticleData] = useState(null);
    const [otherArticles, setOtherArticles] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://api.net-zap.com/api.php?action=get_other_articles&article_id=${id}`)
            .then(response => response.json())
            .then(data => setOtherArticles(data))
            .catch(error => console.error('Error fetching other articles:', error));

        fetch(`https://api.net-zap.com/api.php?action=get_article_details&article_id=${id}`)
            .then(response => response.json())
            .then(data => setArticleData(data[0]))
            .catch(error => console.error('Error fetching articles:', error));
    }, [id]);

    if (!articleData) {
        return (
            <div className='article-details-section'>
                <div className='article-loading-container'>
                    <p>Loading..</p>
                </div>
            </div>
        );
    }

    const { article_title, author, section_heading, section_text, subsection_heading, subsection_text, image_data } = articleData;

    return (
        <>
            <div className='article-details-section'>
                <div className='article-details-container'>
                    <div className='wrapper'>
                        <img
                            src={`data:image/jpeg;base64,${image_data}`}
                            alt="Description of the image"
                            style={{ width: '100%', height: 'auto', aspectRatio: '16/9' }}
                        />
                    </div>
                    <p className='article-title'>{article_title}</p>
                    <p className='article-author'>{author}</p>
                    <div className="table-of-contents">
                        <p className='article-content-table'>Table of contents</p>
                        <ul>
                            <li key={section_heading}><a href={`#${section_heading}`}>{section_heading}</a></li>
                        </ul>
                    </div>
                    <div className='article-content'>
                        <div key={section_heading}>
                            <p id={section_heading} className='article-head'>{section_heading}</p>
                            <p className='article-text'>{section_text}</p>
                            <div key={subsection_heading}>
                                <h3 id={subsection_heading} className='article-head'>{subsection_heading}</h3>
                                <p className='article-text'>{subsection_text}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='another-articles-container'>
                    <p className='article-subtitle'>Artikel Lainnya</p>
                    {otherArticles.map((article, index) => (
                        <div className='another-article-card' key={index}>
                            <div className='wrapper'>
                                <img
                                    src={`data:image/jpeg;base64,${article.image_data}`}
                                    alt="Description of the image"
                                    style={{ width: '100%', height: 'auto', aspectRatio: '16/9' }}
                                />
                            </div>
                            <p className='another-article-title'>{article.title}</p>
                            <p className='another-article-content'>{article.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default ArticleDetails;
