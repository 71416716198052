import React from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Background_4 from '../../assets/background-service-4.png'
import Slide1 from '../../assets/slide1_3.png'
import Slide2 from '../../assets/slide2_3.png'
import Slide3 from '../../assets/slide3_3.png'
import Slide4 from '../../assets/slide4_3.png'
import Slide5 from '../../assets/slide5_3.png'
import Slide6 from '../../assets/slide6_3.png'




export default function FourthSection(){
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false,
        nextArrow: <></>,
        autoplay: true,
        autoplaySpeed: 2000,
      };
    return(
        <section id="secondSection" style={{paddingTop:200, paddingBottom:100}}>
            <div className="container">
                <div className="left-column-sec">
                    <div className="background-section-2">
                        <img src={Background_4}  alt="netzap-background-4"/>
                    </div>
                </div>
                <div className="right-column-sec">
                    <div className="middle-2">
                        <h1>
                            Internet untuk UMKM
                            <br />
                            dan Backup Wifi
                        </h1>
                        <div className="square-cont">
                        <div className="word">Fiber Optic</div>
                        <div className="word">Wireless</div>
                        <div className="word">Satelite</div>
                        </div>
                        <div className="h3-cont">
                        <h3>Layanan yang diberikan</h3>
                        </div>
                        <div className="slider-container">
                            <Slider {...settings} className="slider-sets">
                                <div className="image-cont">
                                <h4>Check-Up</h4>
                                <img src={Slide1} className="slide-image"  alt="netzap-slide" />
                                <p>Pemeriksaan untuk memperbaiki 
                                    <br/>
                                    jaringan internet yang
                                    <br/>
                                    lambat atau mati
                                </p>
                                </div>
                                <div className="image-cont">
                                <h4>Desain Hasil
                                    <br/>
                                    Check-Up
                                </h4>
                                <img src={Slide2} className="slide-image"  alt="netzap-slide"/>
                                <p>Proses pembuatan atau 
                                    <br/>
                                    optimalisasi jaringan 
                                    <br/>
                                    internet yang lambat atau
                                    <br/> mati
                                </p>
                                </div>
                                <div className="image-cont">
                                <h4>Instalasi</h4>
                                <img src={Slide3} className="slide-image"  alt="netzap-slide"/>
                                <p>
                                    Pemasangan perangkat 
                                    <br/>jaringan internet (kabel, 
                                    <br/>
                                    router, access point, dan
                                    <br/> computer)
                                </p>

                                </div>
                                <div className="image-cont">
                                <h4>Perbaikan</h4>
                                <img src={Slide4} className="slide-image" alt="netzap-slide" />
                                <p>
                                    Memperbaiki kondisi dan 
                                    <br/>
                                    fungsi jaringan internet 
                                    <br/>
                                    hyang lambat atau mati
                                </p>
                                </div>
                                <div className="image-cont">
                                <h4>Monitoring Support</h4>
                                <img src={Slide5} className="slide-image"  alt="netzap-slide"/>
                                <p>Memberikan informasi 
                                    <br/>
                                    mengenai sebab dan 
                                    <br/>
                                    akibat dari suatu masalah
                                </p>
                                </div>
                                <div className="image-cont">
                                <h4>Perawatan</h4>
                                <img src={Slide6} className="slide-image"  alt="netzap-slide"/>
                                <p>
                                    Perawatan perangkat 
                                    <br/>
                                    secara rutin dan sistematis
                                    <br/> agar kondisi tetap optimal
                                </p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>

      </section>
    )
}