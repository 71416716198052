import React from "react";
import './ThirdSection.css'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Background_3 from '../../assets/background-service-3.png'
import Slide1 from '../../assets/slide1_1.png'
import Slide2 from '../../assets/slide2_2.png'

export default function thirdSection(){
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false,
        nextArrow: <></>,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000,
      };
    return(
        <section id="thirdSection" style={{paddingTop:200, paddingBottom:100}}>
            <div className="container">
                <div className="left-column-thr">
                    <div className="middle">
                        <h1>Internet untuk UMKM dan Backup Wifi</h1>
                        <div className="square-cont-2">
                            <div className="word">Fiber Optic</div>
                            <div className="word">Wireless</div>
                            <div className="word">Satelite</div>
                        </div>
                    </div>
                    <div className="h3-cont-2">
                        <h3>Layanan yang diberikan</h3>
                    </div>
                    <div className="slider-container-2">
                        <Slider {...settings} className="slider-sets">
                            <div className="image-cont">
                                <h4>Konsep on Demand</h4>
                                <img src={Slide1}className="slide-image"  alt="netzap-slide"/>
                                <li>Kontrak berlangganan sesuai kebutuhan</li>
                                <li>Harga layanan sesuai budget</li>
                            </div>
                            <div className="image-cont">
                                <h4>Support</h4>
                                <img src={Slide2}className="slide-image"  alt="netzap-slide"/>
                                <li>Alarm Gangguan Realtime</li>
                                <li>TS visit dalam 2 Jam untuk <br/>tangani Downtime</li>
                                <li>Restitusi saat Downtime</li>
                            </div>
                        </Slider>
                    </div>
                    
                </div>
                <div className="right-column-thr">
                    <div className="background-section-3">
                        <img src={Background_3} alt="netzap-background-3"/>
                    </div>

                </div>
            </div>

      </section>
    )
}