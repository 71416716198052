import React from 'react';
import { Link } from 'react-router-dom';
import ServiceCarousel from './ServiceCarousel.js';
import './Services.css';

function Services() {
    return (
        <>
            <div className='services-section'>
                <h1 className='title-list-of-services'>Layanan</h1>
                <ServiceCarousel />
                <div className="button-container">
                    <Link to='/service-page'>
                        <button type='button'>Lihat lainnya</button>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Services;
