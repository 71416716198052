import React, { useEffect, useState } from 'react';
import './Articles.css'
import CardArticle from './CardArticle'

function HighlightedArticles() {
    const [articles, setArticles] = useState([]);

    const apiUrl = 'https://api.net-zap.com/api.php?action=get_top_articles';

    useEffect(() => {
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => setArticles(data))
        .catch(error => console.error('Error fetching articles:', error));
    }, []);

    return (
        <>
            <div className="article-section">
                <h1 className='title-list-of-articles'>Artikel</h1>
                <div className='article-container'>
                    {articles.map((article, index) => (
                        <CardArticle
                            key={index}
                            image={article.image_data}
                            id={article.article_id}
                            title={article.title}
                            description={article.description}
                        />
                    ))}
                </div>
                <div className="button-container">
                    <button type='button' onClick={() => { window.location.href = "/article" }}>Lihat lainnya</button>
                </div>
            </div>
        </>
    )
}

export default HighlightedArticles
