import React from "react";
import './FirstSection.css'
import Background_1 from '../../assets/background-service-1.png'
import image_1 from '../../assets/upto200mbps.png'
import FormModal from '../FormModal/formModal.js'

export default function FirstSection(){
    const [showModal, setShowModal] = React.useState(false);
    const handleOpenModal = () => {
        setShowModal(true);
      };
    
      const handleCloseModal = () => {
        setShowModal(false);
      };

      const handleOpenWhatsApp = () => {
        window.open("https://wa.me/08217396364", "_blank");
    };

    return(
        <section id="firstSection" style={{paddingTop:10}}>
            <div className="container">
                <div className="left-column">
                    <div className="text-1">
                        <h1>Internet untuk<br/>Kampung RW</h1>
                        <p>Sedikitnya daerah dengan akses internet yang layak, khususnya di daerah pinggir kota, mendorong netZAP untuk mewujudkan akses internet di wilayah pinggiran di seluruh Indonesia melalui kolaborasi dengan pengurus daerah (RW) setempat.</p>
                        <p>netZAP berkomitmen membangun jaringan internet tanpa batas wilayah di seluruh Indonesia.</p>
                        <div className="container-2">
                            <div className="image-1">
                                <img src={image_1} alt="netzap-props-1"/>
                            </div>
                            <div className="allign-item">
                                <p className="p-harga">Harga mulai</p>
                                <div className="container-3">
                                    <p className="p-rp">Rp</p>
                                    <h2 className="h-harga">170.000</h2>
                                </div>
                                <div className="button-container-1">
                                    <button className="button-daftar" onClick={handleOpenModal}>Daftar</button>
                                    <button className="button-sales" onClick={handleOpenWhatsApp}>Hubungi Sales</button>                                </div>
                            </div>
  
                        </div>
                    </div>
                </div>
                <div className="right-column">
                    <div className="background-section">
                        <img src={Background_1} alt="Netzap-Background-service-page"/>
                    </div>
                </div>
            </div>
            <FormModal open={showModal} onClose={handleCloseModal}></FormModal>


      </section>
    )
}