import React from "react";
import Background_2 from "../../assets/background-service-2.png";
import "./SecondSection.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide1 from "../../assets/slide1.png";
import Slide2 from "../../assets/slide2.png";
import Slide3 from "../../assets/slide3.png";
import Slide4 from "../../assets/slide4.png";
import Slide5 from "../../assets/slide5.png";
import Slide6 from "../../assets/slide6.png";

export default function SecondSection() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    nextArrow: <></>,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <section id="secondSection" style={{ paddingTop: 100 }}>
      <div className="container">
        <div className="left-column-sec">
          <div className="background-section-2">
            <img src={Background_2} alt="netzap-background-2"/>
          </div>
        </div>
        <div className="right-column-sec">
          <div className="middle-2">
            <h1>
              Internet untuk
              <br />
              Event
            </h1>
            <div className="square-cont">
              <div className="word">Live Streaming</div>
              <div className="word">Konser Musik</div>
              <div className="word">Exhibition</div>
              <div className="word">Live Gathering & Wedding</div>
            </div>
            <div className="square-cont">
              <div className="word">Seminar</div>
              <div className="word">Virtual Meeting</div>
              <div className="word">Training</div>
              <div className="word">Dan Lainnya</div>
            </div>
            <div className="h3-cont">
              <h3>Layanan yang diberikan</h3>
            </div>
            <div className="slider-container">
              <Slider {...settings} className="slider-sets" alt="netzap-slide">
                <div className="image-cont">
                  <h4>TB Setup</h4>
                  <img src={Slide1} className="slide-image"  alt="netzap-slide"/>
                  <li>Instalasi kabel LAN/Wifi</li>
                  <li>Setting Perangkat</li>
                </div>
                <div className="image-cont">
                  <h4>Jaringan Internet Multiprovider</h4>
                  <img src={Slide2} className="slide-image"  alt="netzap-slide"/>
                  <li>Internet via Fiber Optic</li>
                  <li>Internet Wireless VSAT</li>
                </div>
                <div className="image-cont">
                  <h4>Survey Lokasi</h4>
                  <img src={Slide3} className="slide-image"  alt="netzap-slide"/>

                  <li>Visit ke Venue.</li>
                  <li>
                    Menentukan posisi perangkat
                    <br />
                    sesuai request.
                  </li>
                  <li>
                    Menghitung Jumlah
                    <br /> perangkat yang di butuhkan.
                  </li>
                </div>
                <div className="image-cont">
                  <h4>Design Jaringan</h4>
                  <img src={Slide4} className="slide-image"  alt="netzap-slide"/>
                  <p>
                    Membuat design jaringan
                    <br /> sesuai dengan keinginan
                  </p>
                </div>
                <div className="image-cont">
                  <h4>TB Standby</h4>
                  <img src={Slide5} className="slide-image"  alt="netzap-slide"/>
                  <p>Monitoring & troubleshoot langsung</p>
                </div>
                <div className="image-cont">
                  <h4>Konsultasi</h4>
                  <img src={Slide6} className="slide-image"  alt="netzap-slide"/>
                  <p>
                    Memberikan layanan tanya jawab (Konsultasi)
                    <br /> tentang kebutuhan internet untuk event.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
