import React from 'react'
import Navbar from '../components/Navbar/Navbar.js';
import Footer from '../components/Footer/Footer.js';
import ArticleDetails from '../components/ListOfArticles/ArticleDetails.js';

function ArticleDetailsPage() {
    return (
        <div>
            <Navbar></Navbar>
            <ArticleDetails></ArticleDetails>
            <Footer></Footer>
        </div>
    )
}

export default ArticleDetailsPage