import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Hero.css";

function Hero() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false
  };

  return (
    <Slider {...settings}>
      <div className="hero-slide">
        <div className="hero-container">
          <h1 className="title-hero">Pembangunan Jaringan Internet Merata dengan netZAP</h1>
          <p className="description">netZAP berkolaborasi dengan pengurus daerah (RW) untuk membangun infrastruktur internet di daerah pinggiran kota.</p>
          <div className="button-container-hero">
            <button type="submit">Daftar lokasi/acara secara GRATIS</button>
            <button type="submit">Hubungi Kami untuk Konsultasi</button>
          </div>
        </div>
      </div>
      <div className="hero-slide">
        <div className="hero-container">
          <h1 className="title-hero">Wujudkan Event tanpa Gangguan Teknis Bersama netZAP</h1>
          <p className="description">netZAP berkolaborasi dengan pengurus daerah (RW) untuk membangun infrastruktur internet di daerah pinggiran kota.</p>
          <div className="button-container-hero">
            <button type="submit">Daftar lokasi/acara secara GRATIS</button>
            <button type="submit">Hubungi Kami untuk Konsultasi</button>
          </div>
        </div>
      </div>
      <div className="hero-slide">
        <div className="hero-container">
          <h1 className="title-hero">Dapatkan Tawaran Menarik.....</h1>
          <p className="description">netZAP berkolaborasi dengan pengurus daerah (RW) untuk membangun infrastruktur internet di daerah pinggiran kota.</p>
          <div className="button-container-hero">
            <button type="submit">Daftar lokasi/acara secara GRATIS</button>
            <button type="submit">Hubungi Kami untuk Konsultasi</button>
          </div>
        </div>
      </div>
    </Slider>
  );
}

export default Hero;
